<template>
  <h2 :class="{'bg-danger': currentAnswerIncorrect }">{{ currentProblem.top }} {{ currentProblem.symbol }} {{ currentProblem.bottom }} = {{ currentAnswer }}</h2>
  <div class="container number-pad">
    <div class="row">
      <div class="col-xs-4">
        <button v-on:click="numberPressed(7)" class="btn btn-block btn-primary btn-lg" type="button">7</button>
      </div>
      <div class="col-xs-4">
        <button v-on:click="numberPressed(8)" class="btn btn-block btn-primary btn-lg" type="button">8</button>
      </div>
      <div class="col-xs-4">
        <button v-on:click="numberPressed(9)" class="btn btn-block btn-primary btn-lg" type="button">9</button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4">
        <button v-on:click="numberPressed(4)" class="btn btn-block btn-primary btn-lg" type="button">4</button>
      </div>
      <div class="col-xs-4">
        <button v-on:click="numberPressed(5)" class="btn btn-block btn-primary btn-lg" type="button">5</button>
      </div>
      <div class="col-xs-4">
        <button v-on:click="numberPressed(6)" class="btn btn-block btn-primary btn-lg" type="button">6</button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4">
        <button v-on:click="numberPressed(1)" class="btn btn-block btn-primary btn-lg" type="button">1</button>
      </div>
      <div class="col-xs-4">
        <button v-on:click="numberPressed(2)" class="btn btn-block btn-primary btn-lg" type="button">2</button>
      </div>
      <div class="col-xs-4">
        <button v-on:click="numberPressed(3)" class="btn btn-block btn-primary btn-lg" type="button">3</button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-3">
        <button v-on:click="numberPressed(0)" class="btn btn-block btn-primary btn-lg" type="button">0</button>
      </div>
      <div class="col-xs-3">
        <button v-on:click="numberPressed('-')" class="btn btn-block btn-primary btn-lg" type="button">-</button>
      </div>
      <div class="col-xs-3">
        <button v-on:click="reset()" class="btn btn-block btn-default btn-lg" type="button">Clear</button>
      </div>
      <div class="col-xs-3">
        <button :disabled="!answerEntered" v-on:click="submitAnswer()" class="btn btn-block btn-success btn-lg" type="button">Submit</button>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-4"></div>
      <div class="col-xs-4">
        <button v-if="hasCompletedMinimum" v-on:click="finishSession()" class="btn btn-block btn-info btn-lg" type="button">Finish</button>
      </div>
      <div class="col-xs-4"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NumberPad',
  props: {
    currentProblem: Object,
    hasCompletedMinimum: Boolean,
    currentAnswer: String,
    currentAnswerIncorrect: Boolean,
  },
  computed: {
    // Disables the submit button unless the user has entered a (possibly
    // negative) numeric answer.
    answerEntered () {
      return /^-?[0-9]+$/.test(this.currentAnswer);
    },
  },
  created() {
    // Listens for keypresses that correspond to the number pad.
    if (!this.$parent.eventsAdded) {
      window.addEventListener('keydown', (ev) => {
        if (/^[0-9]$/.test(ev.key)) {
          this.numberPressed(ev.key);
          ev.preventDefault();
        }
        else if (ev.key === '-') {
          this.numberPressed(ev.key);
          ev.preventDefault();
        }
        else if (ev.key === 'Backspace') {
          this.reset();
          ev.preventDefault();
        }
        else if (ev.key === 'Enter') {
          this.submitAnswer();
          ev.preventDefault();
        }
      });
      this.$parent.eventsAdded = true;
    }
  },
  methods: {
    numberPressed: function (newValue) {
      this.$parent.NumberPadPressed(newValue);
    },
    reset: function () {
      this.$parent.NumberPadReset();
    },
    submitAnswer: function () {
      this.$parent.handleSubmit();
    },
    finishSession: function () {
      this.$parent.finishSession();
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .number-pad > .row > * {
    padding: 0.5rem;
  }
  .number-pad button {
    font-size: 2.2rem;
    padding: 1.5rem 0;
  }
</style>
