import getSample from 'lodash/sample';
import getRange from 'lodash/range';

const getOperand = (start, end) => {
  return getSample(getRange(start, end + 1));
}

export default function(preset) {
  let symbol;
  let top;
  let bottom;
  let answer;

  const problemType = getSample(preset.operators);
  switch (problemType.operator) {
    case 'add':
      symbol = '+';
      top = getOperand(problemType.top.start, problemType.top.end);
      bottom = getOperand(problemType.bottom.start, problemType.bottom.end);
      answer = top + bottom;
      break;

    case 'subtract':
      symbol = '-';
      top = getOperand(problemType.top.start, problemType.top.end);
      bottom = getOperand(problemType.bottom.start, problemType.bottom.end);
      // Prevents negative answers.
      if (bottom > top) {
        let origTop = top;
        let origBottom = bottom;
        top = origBottom
        bottom = origTop;
      }
      answer = top - bottom;
      break;

    case 'multiply':
      symbol = 'x';
      top = getOperand(problemType.top.start, problemType.top.end);
      bottom = getOperand(problemType.bottom.start, problemType.bottom.end);
      answer = top * bottom;
      break;

    case 'divide':
      symbol = '/';
      answer = getOperand(problemType.top.start, problemType.top.end);
      bottom = getOperand(problemType.bottom.start, problemType.bottom.end);
      // TODO Add validation to ensure that zero is not the only bottom option.
      while (bottom === 0) {
        bottom = getOperand(problemType.bottom.start, problemType.bottom.end);
      }
      top = bottom * answer;
      break;

    default:
      break;
  }

  return {
    symbol,
    top,
    bottom,
    answer,
  };
}