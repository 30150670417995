<template>
    <label>
      Date
      <input type="date" v-model="date" v-on:change="fetchResults()" />
    </label>

  <div v-if="summaries.length < 1" >
    No results
  </div>
  <div class="details-container" v-for="summary in summaries" :key="summary.name">
    <details>
      <summary>{{ summary.name }}: {{ summary.rowsCorrect.length }} correct, {{ summary.rowsInCorrect.length }} incorrect</summary>
      <ReportDetailed :problemsReport="summary.rowsForReport" />
    </details>
    <div></div>
  </div>
</template>

<script>

const urlResultsByDate = process.env.VUE_APP_BASE_URL + '/api/submissions-by-date/';

import ReportDetailed from './ReportDetailed.vue';

export default {
  name: 'ResultsByDate',
  components: {
    ReportDetailed,
  },
  data() {
    return {
      summaries: [],
      // Gets current date in YYYY-MM-DD format.
      // Thanks to https://stackoverflow.com/a/35922073.
      date: new Date().toISOString().slice(0, 10),
    };
  },
  created () {
    this.fetchResults();
  },
  methods: {
    fetchResults: function() {
      // Resets the results in case date is being changed.
      this.summaries = [];

      fetch(urlResultsByDate + this.date)
        .then(response => response.json())
        .then(rows => {
          const namesAll = rows.map(row => {
            return row.user_id;
          });
          const namesUnique = [...new Set(namesAll)];

          namesUnique.forEach(name => {
            const rowsThisName = rows.filter(row => {
              return row.user_id === name;
            });
            const rowsCorrect = rowsThisName.filter(row => { return row.is_correct === '1' });
            const rowsInCorrect = rowsThisName.filter(row => { return row.is_correct == '0' });

            // Preps the data for ReportDetailed component.
            const rowsForReport = rowsThisName
              .map((row) => {
                return {
                  isCorrect: row.is_correct === '1',
                  id: row.id,
                  submittedAnswer: row.submitted_answer,
                  problem: {
                    top: row.problem_top,
                    bottom: row.problem_bottom,
                    symbol: row.problem_operator,
                  },
                };
              });

            this.summaries.push({
              name,
              rowsCorrect,
              rowsInCorrect,
              rowsForReport,
            });
          });
        });
    },
  },
};

</script>

<style scoped>
  details {
    border-bottom: 1px solid #aaa;
    border-radius: 4px;
    padding: .5em .5em 0;
  }

  summary {
    display: list-item;
    font-weight: bold;
    margin: -.5em -.5em 0;
    padding: .5em;
  }

  details[open] {
    padding: .5em;
  }
</style>