<template>
  <h2>Finished!</h2>
  <div>Correct: {{ countCorrect }}</div>
  <div>Incorrect: {{ countIncorrect }}</div>
  <div>Total: {{ countTotal }}</div>
  <div>Goal: {{ goal }}</div>
  <div>Score: {{ rateCorrect }}%</div>
  <div>Time: {{ duration }}</div>
  <ReportDetailed :problemsReport="problemsReport" />
</template>

<script>
import ReportDetailed from './ReportDetailed.vue';

export default {
  name: 'FinishMessage',
  components: {
    ReportDetailed,
  },
  props: {
    countCorrect: Number,
    countIncorrect: Number,
    goal: Number,
    startTime: Number,
    endTime: Number,
    problemsCompleted: Array,
  },
  computed: {
    countTotal () {
      return this.countCorrect + this.countIncorrect;
    },
    rateCorrect () {
      return (this.countCorrect / (this.countCorrect + this.countIncorrect) * 100).toFixed(0);
    },
    problemsReport () {
      let key = 0;
      return this.problemsCompleted.map((row) => {
        key++;
        return { ...row, id: key}
      });
    },
    // Returns the duration in format "X minute(s), Y second(s)"
    duration () {
      const responseParts = [];
      const durationSeconds = this.endTime - this.startTime;
      var minutes = Math.floor(durationSeconds / 60);
      var seconds = durationSeconds - (minutes * 60);
      if (minutes === 1) {
        responseParts.push('1 minute');
      }
      else if (minutes > 1) {
        responseParts.push(`${minutes} minutes`);
      }
      if (seconds === 1) {
        responseParts.push('1 second');
      }
      else if (seconds > 1 || seconds === 0) {
        responseParts.push(`${seconds} seconds`);
      }

      return responseParts.join(', ');
    },
  },
  methods: {
  },
};

</script>

<style scoped>
</style>