<template>
  <div>{{ countCorrect }} / {{ countTotal }} ({{ rateCorrect }}%). Remaining: {{ goal - countCorrect }}</div>
</template>

<script>
export default {
  name: 'StatisticsMessage',
  props: {
    countCorrect: Number,
    countIncorrect: Number,
    goal: Number,
  },
  computed: {
    countTotal () {
      return this.countCorrect + this.countIncorrect;
    },
    rateCorrect () {
      const total = this.countCorrect + this.countIncorrect;
      if (total === 0) {
        return 0;
      }
      else {
        return (this.countCorrect / total * 100).toFixed(0);
      }
    },
  },
  methods: {
  },
};

</script>

<style scoped>
</style>