<template>
  <div>
    <ol class="report">
      <li :class="{'bg-danger': !row.isCorrect, 'report-row': true }" v-for="row in problemsReport" v-bind:key="row.id">
        <span class="report-row__inner">
          <span class="report-row__top">{{ row.problem.top }}</span>
          <span class="report-row__operator">{{ row.problem.symbol }}</span>
          <span class="report-row__bottom">{{ row.problem.bottom }}</span>
          <span class="report-row__equals">=</span>
          <span class="report-row__answer">{{ row.submittedAnswer }}</span>
        </span>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'ReportDetailed',
  props: {
    problemsReport: Array,
  },
};

</script>

<style scoped>
  .report {
    max-width: 200px;
    margin: 1rem auto;
  }
  .report-row {
    margin-bottom: 0.5rem;
  }
  .report-row__inner {
    display: flex;
    justify-content: space-evenly;
  }
</style>