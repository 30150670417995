<template>
  <button v-on:click="handlePresetClick(presetId)" v-bind:class="presetButtonClasses">
    <div>{{ presetName }}</div>
  </button>
</template>

<script>
export default {
  name: 'PresetButton',
  props: {
    preset: Object,
    presetName: String,
    presetId: Number,
    isSelected: Boolean,
  },
  computed: {
    presetButtonClasses () {
      return {
        'btn': true,
        'btn-block': true,
        'btn-lg': true,
        'btn-default': !this.isSelected,
        'btn-primary': this.isSelected,
      };
    },
  },
  methods: {
    handlePresetClick: function(id) {
      this.$parent.handlePresetClick(id);
    },
  },
};

</script>

<style scoped>
</style>

