<template>
  <div v-if="state == 'setup'" class="container">
    <div class="row">
      <div class="col-xs-4 form-group" v-for="preset in presets" :key="preset.id">
        <PresetButton :preset="preset" :presetId="preset.id" :presetName="preset.name" :isSelected="presetSelected && presetSelected.id == preset.id" />
      </div>
    </div>
    <div v-if="sessionRecovered" class="row">
      <div class="col-xs-2"></div>
      <div class="col-xs-8 form-group">
        <button v-on:click="resumeRecoveredSession()" class="btn btn-block btn-warning">Continue ({{ sessionRecovered.preset.name }})</button>
      </div>
      <div class="col-xs-2"></div>
    </div>
    <div class="row">
      <div class="col-xs-4"></div>
      <div class="col-xs-4 form-group">
        <button v-if="presetSelected" v-on:click="handleStartClick()" class="btn btn-block btn-success">Start</button>
      </div>
      <div class="col-xs-4"></div>
    </div>

    <div class="row">
      <div class="col-xs-4">
        <button v-on:click="handleShowResults()" class="btn btn-block">Results</button>
      </div>
      <div class="col-xs-4"></div>
      <div class="col-xs-4"></div>
    </div>
  </div>

  <div v-if="state == 'results'" class="container">
    <ResultsByDate />

    <div class="row">
      <div class="col-xs-4">
        <button v-on:click="handleShowHomepage()" class="btn btn-block">Home</button>
      </div>
      <div class="col-xs-4"></div>
      <div class="col-xs-4"></div>
    </div>
  </div>

  <div v-if="state == 'error'" class="container">
    <div class="bg-danger">
      {{ errorMessage }}
    </div>

    <div class="row">
      <div class="col-xs-4">
        <button v-on:click="handleShowHomepage()" class="btn btn-block">Home</button>
      </div>
      <div class="col-xs-4"></div>
      <div class="col-xs-4"></div>
    </div>
  </div>

  <div v-if="state == 'in-progress'" class="container">
    <NumberPad
      v-if="state == 'in-progress'"
      :currentProblem="currentProblem"
      :hasCompletedMinimum="hasCompletedMinimum"
      :currentAnswer="currentAnswer"
      :currentAnswerIncorrect="currentAnswerIncorrect"
    />

    <div>Preset: <strong>{{ presetSelected.name }}</strong></div>

    <StatisticsMessage
      :countCorrect="countCorrect"
      :countIncorrect="countIncorrect"
      :goal="presetSelected.minimum"
    />

    <div class="row">
      <div class="col-xs-4">
        <button v-on:click="handleShowHomepage()" class="btn btn-block">Home</button>
      </div>
      <div class="col-xs-4"></div>
      <div class="col-xs-4"></div>
    </div>
  </div>

  <div v-if="state == 'finished'" class="container">
    <FinishMessage
      :countCorrect="countCorrect"
      :countIncorrect="countIncorrect"
      :goal="presetSelected.minimum"
      :startTime="session.startTime"
      :endTime="session.endTime"
      :problemsCompleted="problemsCompleted"
    />
  </div>
</template>

<script>
import NumberPad from './components/NumberPad.vue';
import PresetButton from './components/PresetButton.vue';
import FinishMessage from './components/FinishMessage.vue';
import StatisticsMessage from './components/StatisticsMessage.vue';
import ResultsByDate from './components/ResultsByDate.vue';

// Utilities
import * as utils from './utilities/misc';
import getNewProblem from './utilities/getNewProblem';

const urlPresets = process.env.VUE_APP_BASE_URL + '/api/presets';
const urlSubmissions = process.env.VUE_APP_BASE_URL + '/api/submissions';

export default {
  name: 'App',
  components: {
    NumberPad,
    PresetButton,
    FinishMessage,
    StatisticsMessage,
    ResultsByDate,
  },
  data() {
    return {
      // The state can be one of:
      // - "setup": User needs to select a preset and start the session.
      // - "in-progress": User has started the session but not finished.
      // - "finished": User has finished the session.
      // - "results": Showing today's results.
      state: 'setup',
      session: {
        id: null,
        preset: null,
        startTime: null,
        endTime: null,
      },
      sessionRecovered: null,
      currentProblem: null,
      currentAnswer: '',
      problemsCompleted: [],
      presets: [],
      presetSelected: null,
      currentAnswerIncorrect: false,
      errorMessage: null,
      eventsAdded: false,
    }
  },
  created() {
    fetch(urlPresets)
      .then(response => response.json())
      .then(data => (this.presets = data));

    const latestSession = JSON.parse(window.localStorage.getItem('session'));
    if (latestSession && !latestSession.endTime) {
      this.sessionRecovered = latestSession;
    }
  },
  computed: {
    countTotal () {
      return this.countCorrect + this.countIncorrect;
    },
    hasCompletedMinimum () {
      return this.countCorrect >= this.presetSelected.minimum;
    },
    countCorrect () {
      return this.problemsCompleted.filter(function(problem) {
        return problem.isCorrect;
      }).length;
    },
    countIncorrect () {
      return this.problemsCompleted.filter(function(problem) {
        return !problem.isCorrect;
      }).length;
    },
  },
  methods: {
    handlePresetClick: function(id) {
      this.presetSelected = this.presets.find(preset => preset.id == id);
    },
    handleStartClick: function() {
      // Resumes the recovered session if the current session is the same as the
      // selected preset.
      if (this.sessionRecovered && this.sessionRecovered.preset && this.sessionRecovered.preset.id == this.presetSelected.id) {
        this.resumeRecoveredSession();
        return;
      }

      this.session.startTime = utils.getCurrentTimestamp();
      this.session.preset = this.presetSelected;
      this.currentProblem = getNewProblem(this.presetSelected);
      this.state = 'in-progress';

      // TODO use the ID from the backend API.
      this.session.id = Math.random().toString(36).substring(7);
      window.localStorage.setItem('session', JSON.stringify(this.session));
    },
    resumeRecoveredSession: function () {
      this.session = this.sessionRecovered;
      this.presetSelected = this.session.preset;
      this.currentProblem = getNewProblem(this.presetSelected);

      const recoveredSubmissions = JSON.parse(window.localStorage.getItem('submissions')) || [];
      this.problemsCompleted = recoveredSubmissions;

      this.state = 'in-progress';
    },
    NumberPadPressed: function (value) {
      this.currentAnswer += value;
    },
    NumberPadReset: function () {
      this.currentAnswer = '';
    },
    handleSubmit: function(submission) {
      const isCorrect = this.currentAnswer == this.currentProblem.answer;

      const submissionToStore = {
        // TODO Build a "session" model in backend.
        'user_id': this.session.preset.name,
        'problem_top': this.currentProblem.top,
        'problem_bottom': this.currentProblem.bottom,
        'problem_operator': this.currentProblem.symbol,
        'problem_answer': this.currentProblem.answer,
        'submitted_answer': this.currentAnswer,
        'is_correct': isCorrect,
      };

      // Sends the submission to backend.
      fetch(urlSubmissions, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionToStore),
      })
      .then((response) => {
        if (!response.ok || response.status !== 201) {
          throw new Error(`System error: ${response.statusText}.`);
        }
        return response.json();
      })
      .catch((error) => {
        this.errorMessage = error;
        this.state = 'error'
      });

      // TODO collect timestamp.
      this.problemsCompleted.push({
        sessionId: this.session.id,
        problem: this.currentProblem,
        submission,
        submittedAnswer: this.currentAnswer,
        submittedTimestamp: utils.getCurrentTimestamp(),
        isCorrect,
      });
      window.localStorage.setItem('submissions', JSON.stringify(this.problemsCompleted));

      if (!isCorrect) {
        this.currentAnswerIncorrect = true;
        window.setTimeout(() => {
          this.currentProblem = getNewProblem(this.presetSelected);
          this.NumberPadReset();
          this.currentAnswerIncorrect = false;
        }, 1500);
      }
      else {
        this.currentProblem = getNewProblem(this.presetSelected)
        this.NumberPadReset();
      }
    },
    finishSession: function () {
      this.session.endTime = utils.getCurrentTimestamp();
      this.state = 'finished';
      window.localStorage.setItem('session', JSON.stringify(this.session));
      // TODO Delete the session from localstorage?
    },
    handleShowHomepage: function () {
      this.state = 'setup';
    },
    handleShowResults: function () {
      this.state = 'results';
    },
  }
}
</script>

<style>
.statistics {
  display: flex;
  width: 50%;
  margin: 0 auto;
  justify-content: space-evenly;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 1rem;
}
</style>
